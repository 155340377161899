import { useState } from 'react';

// import audio related icon from heroicons;

import { postData, getData, evaluateFileType } from 'utils/helpers';
import { trackEvent } from 'utils/analytics';
import {
  ExclamationTriangleIcon,
  PhotoIcon
} from '@heroicons/react/24/outline';
import { EnvelopeIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';

export default function SubmitJob(
  {
    file,
    style,
    handleadd,
    user,
    uploading,
    aspect,
    passUploadingState,
    passSignupState,
    updateRenders,
    roomtype = 'bedroom',
    rendertype = 'interior_design',
    quality = 'low',
    privacy = 'public',
    buttontext = 'Submit Job',
    project = 'default',
    color = 'blue',
    intensity = 50,
    numRenders = 1,
    previousFile,
    previousUrl,
    setPreviousFile,
    setPreviousUrl,
    className,
    subscription,
    userDetails,
    editingMode,
    fileQuick,
    imageUrlQuick,
    maskPrompt,
    styleType,
    selectedMyStyle,
    selectedCustomStyle,
    setQuickImageUrl,
    selectedBrushType
  },
  ...props
) {
  // const [loading, setLoading] = useState(false);

  const [jobStatus, setJobStatus] = useState('Fazendo upload do arquivo...');
  const [showStatus, setshowStatus] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  // go to page using router

  const setLoadingState = (state) => {
    passUploadingState(state);
  };

  const checkUserStatus = (
    file,
    style,
    roomtype,
    rendertype,
    quality,
    privacy,
    user,
    color = 'Recommended',
    brushType,
    callback
  ) => {
    // if (!subscription && userDetails?.completed_jobs > 3) {
    //   setLoadingState('waiting');
    //   const delay = (Math.floor(Math.random() * (40 - 30 + 1)) + 30) * 1000;
    //   // const delay = 31000;
    //   setTimeout(() => {
    //     callback(file, style, roomtype, rendertype, quality, privacy, user);
    //   }, delay);
    // } else {
    callback(
      file,
      style,
      roomtype,
      rendertype,
      quality,
      privacy,
      color,
      user,
      brushType
    );
    // }
  };

  const startMagicEditor = async (
    file,
    style,
    room_type,
    render_type,
    color = 'Recommended',
    quality,
    privacy,
    user,
    brushType
  ) => {
    if (!file) {
      setErrMsg(true);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setLoadingState('uploading');
    setshowStatus(true);

    if (!user) {
      console.log('No User');
      setLoadingState('blank');
      // alert('Please sign in to submit your image!');
      passSignupState(true);
    }

    try {
      if (!file) {
        setJobStatus('Please select a file to upload');
        // alert('Please select a file to upload');
        setLoadingState('blank');
        // throw Error('Please select a file to upload');
      }

      // Limit file types to images

      evaluateFileType(file, setJobStatus, setLoadingState);

      if (
        previousFile &&
        previousFile.name === file.name &&
        previousFile.size === file.size &&
        previousUrl
      ) {
        var url_without_query = previousUrl;
      } else {
        // create random string for file name
        const randomString = Math.random().toString(36).substring(2, 20);

        // create file name
        const randomFileName = `${randomString}-${file.name}`;

        const response = await fetch('/api/r2/uploadFile', {
          method: 'POST',
          body: JSON.stringify({
            name: randomFileName,
            type: file.type
          })
        });

        const { url, id, error } = await response.json();

        if (error) throw Error(error.message);

        // Now we do the actual upload

        const formData = new FormData();

        formData.append('file', file);

        const upload = await fetch(url, {
          method: 'post',
          body: formData
        });

        var url_without_query = `https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/${id}/alldat`;
        const nextUrl = url_without_query;
        const nextFile = structuredClone(file);

        setPreviousFile(nextFile);
        setPreviousUrl(nextUrl);
      }

      // convert duration to integer seconds

      let jobData = {
        input_source_url: url_without_query,
        style: style,
        room_type: room_type,
        render_type: render_type,
        quality: quality,
        project_name: project,
        aspect_ratio: aspect,
        color: color,
        intensity: intensity,
        public_status: privacy === 'public' ? true : false,
        output_url: url_without_query,
        interaction_type: 'smart_edit',
        brush_type: brushType
      };

      const { data: job } = await postData({
        url: '/api/jobs',
        data: jobData
      });
      // Visual feedback to user
      setJobStatus('Completion');
      setLoadingState('completed');
      trackEvent('magic_edit_warmup', {
        event_category: 'engagement',
        event_label: 'start_smart_edit',
        value: job.id
      });
      handleadd([job], false, true);
      updateRenders();
      // trackEvent('render_completed', {
      //   event_category: 'engagement',
      //   event_label: 'render_complete',
      //   value: jobStatus.id
      // });
    } catch (error) {
      if (error) return console.log(error.message);
    }
  };

  const startJob = async (
    file,
    style,
    room_type,
    render_type,
    quality,
    privacy,
    color = 'Recommended',
    user,
    fileQuick,
    imageUrlQuick,
    editingMode,
    maskPrompt,
    styleType,
    selectedMyStyle,
    selectedCustomStyle,
    setQuickImageUrl
  ) => {
    if (!file) {
      setErrMsg(true);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setLoadingState('uploading');
    setshowStatus(true);

    if (!user) {
      console.log('No User');
      setLoadingState('blank');
      // alert('Please sign in to submit your image!');
      passSignupState(true);
    }

    try {
      if (!file) {
        setJobStatus('Please select a file to upload');
        // alert('Please select a file to upload');
        setLoadingState('blank');
        throw Error('Please select a file to upload');
      }

      if (editingMode === 'quickstyles' && fileQuick[0] === null) {
        setJobStatus('Please select a file to upload');
        // alert('Please select a file to upload');
        setLoadingState('blank');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // throw Error('Please select a file to upload');
        return;
      }

      evaluateFileType(file, setJobStatus, setLoadingState);

      if (
        previousFile &&
        previousFile.name === file.name &&
        previousFile.size === file.size &&
        previousUrl
      ) {
        var url_without_query = previousUrl;
      } else {
        // create random string for file name
        const randomString = Math.random().toString(36).substring(2, 20);

        // create file name
        const randomFileName = `${randomString}-${file.name}`;

        const response = await fetch('/api/r2/uploadFile', {
          method: 'POST',
          body: JSON.stringify({
            name: randomFileName,
            type: file.type
          })
        });

        const { url, id, error } = await response.json();

        if (error) throw Error(error.message);

        // Now we do the actual upload

        const formData = new FormData();

        formData.append('file', file);

        const upload = await fetch(url, {
          method: 'post',
          body: formData
        });

        var url_without_query = `https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/${id}/alldat`;
        const nextUrl = url_without_query;
        const nextFile = structuredClone(file);

        setPreviousFile(nextFile);
        setPreviousUrl(nextUrl);
      }

      var url_without_query_quick = '';
      var uploadedUrls = [];

      if (editingMode === 'quickstyles') {
        // loop through the filequick array and upload each file
        // concatanate the urls and pass it to the jobData (use | as the separator)

        uploadedUrls = [];

        for (let i = 0; i < fileQuick.length; i++) {
          const file = fileQuick[i];

          if (!file) {
            setErrMsg(true);
          } else {
            const imageUrl = imageUrlQuick[i];

            const randomString = Math.random().toString(36).substring(2, 20);

            // create file name
            const randomFileName = `${randomString}-${file.name}`;

            const response = await fetch('/api/r2/uploadFile', {
              method: 'POST',
              body: JSON.stringify({
                name: randomFileName,
                type: file.type
              })
            });

            const { url, id, error } = await response.json();

            if (error) throw Error(error.message);

            // Now we do the actual upload

            const formData = new FormData();

            formData.append('file', file);

            const upload = await fetch(url, {
              method: 'post',
              body: formData
            });

            // url_without_query_quick += `https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/${id}/public`;

            uploadedUrls.push(
              `https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/${id}/alldat`
            );
          }

          url_without_query_quick = uploadedUrls.join('|');
        }

        // const randomString = Math.random().toString(36).substring(2, 20);

        // // create file name
        // const randomFileName = `${randomString}-${fileQuick.name}`;

        // const response = await fetch('/api/r2/uploadFile', {
        //   method: 'POST',
        //   body: JSON.stringify({
        //     name: randomFileName,
        //     type: fileQuick.type
        //   })
        // });

        // const { url, id, error } = await response.json();

        // if (error) throw Error(error.message);

        // // Now we do the actual upload

        // const formData = new FormData();

        // formData.append('file', fileQuick);

        // const upload = await fetch(url, {
        //   method: 'post',
        //   body: formData
        // });

        // var url_without_query_quick = `https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/${id}/public`;
        // const nextUrl = url_without_query;
        // const nextFile = structuredClone(file);

        // setPreviousFile(nextFile);
        // setPreviousUrl(nextUrl);
      }

      var activeStyle = style;

      if (styleType === 'my styles') {
        url_without_query_quick =
          selectedMyStyle['inspiration_images'].join('|');
        activeStyle = selectedMyStyle.public_name;
      } else if (styleType === 'community') {
        url_without_query_quick =
          selectedCustomStyle['inspiration_images'].join('|');
        activeStyle = selectedCustomStyle.public_name;
      }

      if (editingMode === 'quickstyles') {
        activeStyle = 'Style Mix';
      }

      if (uploadedUrls.length > 0) {
        setQuickImageUrl(uploadedUrls);
      }

      const neutralizeStyle =
        editingMode === 'quickstyles' ? true : styleType != 'classical';

      // convert duration to integer seconds

      let jobData = {
        input_source_url: url_without_query,
        style: activeStyle,
        room_type: room_type,
        render_type: render_type,
        quality: quality,
        project_name: project,
        color: color,
        aspect_ratio: aspect,
        intensity: intensity,
        public_status: privacy === 'public' ? true : false,
        interaction_type: 'render',
        numRenders: numRenders,
        inspiration_image: url_without_query_quick,
        mask_prompt: maskPrompt,
        neutralize_style: neutralizeStyle
      };

      let addJob = { ...jobData, render_type: render_type };

      // const { data: job } = await postData({
      //   url: '/api/jobs',
      //   data: jobData
      // });

      const { data: job } = await postData({
        url: '/api/jobs',
        data: addJob
      });

      // Visual feedback to user
      handleadd([job], false);

      trackEvent('render_submitted', {
        event_category: 'engagement',
        event_label: 'start_render',
        value: job.id
      });

      const fetchDataFromAPI = async (jobId, delay = 1000) => {
        const fetchWithTimeout = async () => {
          const controller = new AbortController();
          const signal = controller.signal;
          const timeoutId = setTimeout(() => controller.abort(), 4000000);
          const response = await fetch(`/api/jobs/${jobId}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'same-origin',
            signal
          });
          clearTimeout(timeoutId);
          return response;
        };

        try {
          const response = await fetchWithTimeout();
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          const jobStatus = data.data;

          const status = jobStatus[0].status;

          if (status === 'completed') {
            return jobStatus;
          } else if (status === 'failed') {
            throw new Error(`Job ${jobId} failed`);
          } else {
            await new Promise((resolve) => setTimeout(resolve, delay));
            return fetchDataFromAPI(jobId, delay);
          }
        } catch (error) {
          if (error.name === 'AbortError') {
            console.log('Fetch timed out. Retrying immediately...');
            return fetchDataFromAPI(jobId, 1000);
          } else {
            throw error;
          }
        }
      };

      const fetchDataFromAPIWithTimeout = async (jobId, timeout = 60000000) => {
        const fetchData = fetchDataFromAPI(jobId);
        const timer = new Promise((resolve, reject) => {
          setTimeout(() => {
            reject(new Error(`Job ${jobId} timed out`));
          }, timeout);
        });

        return Promise.race([fetchData, timer]);
      };
      const updateStateAfterBothJobs = async (job1) => {
        try {
          const [result1] = await Promise.allSettled([
            fetchDataFromAPIWithTimeout(job1.id)
            // fetchDataFromAPIWithTimeout(job2.id)
          ]);

          const completedJobs = [];

          if (result1.status === 'fulfilled') {
            completedJobs.push(...result1.value);
          }

          // if (result2.status === 'fulfilled') {
          //   completedJobs.push(...result2.value);
          // }

          if (completedJobs.length > 0) {
            setJobStatus(`Completion`);
            setLoadingState('completed');
            handleadd(completedJobs, false);
            updateRenders();
          } else {
            console.error('Both jobs failed or timed out');
          }
        } catch (error) {
          console.error('Error updating state:', error);
        }
      };

      // Start polling for job
      if (job.status === 'processing') {
        updateStateAfterBothJobs(job);
      }
    } catch (error) {
      // setJobStatus('Completion');

      if (file) {
        setLoadingState('blank');
        handleadd([], false, false, true);
        console.log(error);
      }
      if (error) return console.log(error.message);
    }
  };

  return (
    <div className="flex flex-col w-full">
      {(editingMode === 'basic' || editingMode === 'quickstyles') && (
        <button
          className={
            'mt-6 justify-center w-full py-3 border border-transparent font-medium rounded-xl shadow-sm text-white bg-black hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-base p-4' +
            (uploading === 'uploading' ? 'animate-pulse' : '')
          }
          onClick={() =>
            // checkUserStatus(
            //   file,
            //   style,
            //   roomtype,
            //   rendertype,
            //   quality,
            //   privacy,
            //   user,
            //   startJob
            // )
            startJob(
              file,
              style,
              roomtype,
              rendertype,
              quality,
              privacy,
              color,
              user,
              fileQuick,
              imageUrlQuick,
              editingMode,
              maskPrompt,
              styleType,
              selectedMyStyle,
              selectedCustomStyle,
              setQuickImageUrl
            )
          }
        >
          {buttontext}
        </button>
      )}

      {editingMode === 'advanced' && (
        <button
          className={
            'bg-[#4138C2] hover:bg-indigo-700 mt-1 justify-center w-full py-3 border border-transparent font-medium rounded-lg shadow-sm text-white focus:outline-none focus:ring-2 text-base p-4 flex' +
            (uploading === 'uploading' ? 'animate-pulse' : '')
          }
          onClick={() =>
            checkUserStatus(
              file,
              style,
              roomtype,
              rendertype,
              quality,
              privacy,
              color,
              user,
              selectedBrushType,
              startMagicEditor
            )
          }
        >
          <PhotoIcon height="25px" className="mr-2"></PhotoIcon>{' '}
          {'Open Advanced Editor'}
        </button>
      )}
      {!file && errMsg && (
        <div className="mt-2 bg-yellow-50 border-2 border-yellow-500 rounded-lg flex flex-row items-center justify-center text-yellow-700 py-3 ">
          <ExclamationTriangleIcon className="w-6"></ExclamationTriangleIcon>
          <p className="ml-2 text-sm font-semibold">
            Please upload your image above to continue
          </p>
        </div>
      )}
    </div>
  );
}
